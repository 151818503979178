import React from "react";
import UIkit from "uikit";

const Hero = () => {
  return (
    <div
      id="hero"
      className="uk-background-cover uk-background-top-center uk-panel uk-flex uk-flex-center uk-flex-middle"
    >
      <div className="uk-container uk-align-center uk-overlay uk-text-center">
        <p className="job-title uk-text-lowercase uk-margin-remove-bottom">
          Full-Stack Software Engineer
        </p>
        <p className="location uk-text-uppercase uk-margin-large-bottom uk-margin-remove-top">
          nyc
        </p>
        <button
          id="btn-hero"
          className="uk-button uk-text-lowercase uk-button-large"
          onClick={e => UIkit.scroll(e.target).scrollTo("#contact")}
        >
          let's chat
        </button>
      </div>
    </div>
  );
};

export default Hero;
