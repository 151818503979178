import React from "react";

const About = () => {
  return (
    <div className="uk-section" id="about">
      <div className="uk-container uk-container-small">
        <div className="uk-flex" data-uk-grid>
          <div
            className="uk-width-2-3@s uk-padding-large uk-padding-remove-vertical"
            data-uk-scrollspy="cls: uk-animation-slide-left; repeat: false; delay: 300; offset-top: -20"
          >
            <h1>Hi! I'm Ziv...</h1>
            <p>
              ...and I've been into web development since i was 12. i taught
              myself HTML at that age, and even learned a bit of Pascal at the
              time.
            </p>
            <p>
              fast forward several years, and i graduated college with a
              bachelor's degree in Computer Science. i built a mapping system
              for our college library to help students find books. i took on an
              internship at an e-commerce company where i designed and built 3
              custom WordPress themes and websites in the first month.
            </p>
            <p>
              i went on to join that team full time, and over the next 9 years
              managed many aspects of running an online store, including SEO,
              email marketing, A/B testing, technology vendor management, and
              data migrations.
            </p>
            <p>
              but my <i>favorite</i> tasks always involved writing software, be
              it frontend or backend development. now i'm looking for a great
              engineering team to join, where i can{" "}
              <b>work on projects that make a difference in people's lives.</b>
            </p>
            <p>
              if you would like to learn more about how i can help your
              engineering team, email me and we can set up a time to chat!
            </p>
            <ul id="links" className="uk-iconnav">
              <li>
                <a href="#contact" data-uk-scroll="offset: 40">
                  <span data-uk-icon="icon: mail"></span>
                  email
                </a>
              </li>
              <li>
                <a href="https://www.github.com/zkarmi">
                  <span data-uk-icon="icon: github"></span>
                  github
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/nycdev">
                  <span data-uk-icon="icon: linkedin"></span>
                  linkedin
                </a>
              </li>
            </ul>
          </div>
          <div
            className="uk-width-1-3@s uk-padding-remove-left@s"
            data-uk-scrollspy="cls: uk-animation-slide-right; repeat: false; delay: 750; offset-top: -20"
          >
            <p>
              <strong>some technologies i've recently used:</strong>
            </p>
            <ul
              id="technologies"
              className="uk-child-width-1-2 uk-grid-match uk-grid-small"
              data-uk-grid
            >
              {/* <!-- logo icons from http://www.vectorlogo.zone --> */}
              <li className="svg-html5">HTML5</li>
              <li className="svg-css3">CSS3</li>
              <li className="svg-js">JavaScript</li>
              <li className="svg-sass">SASS</li>
              <li className="svg-node">Node.js</li>
              <li className="svg-express">Express.js</li>
              <li className="svg-react">React</li>
              <li className="svg-redux">Redux</li>
              <li className="svg-d3">D3.js</li>
              <li className="svg-mongo">MongoDB</li>
              <li className="svg-psql">PostgreSQL</li>
              <li className="svg-sqlize">Sequelize.js</li>
              <li className="svg-gatsby">Gatsby.js</li>
              <li className="svg-jquery">jQuery</li>
              <li className="svg-wbpk">Webpack</li>
              <li className="svg-mocha">Mocha</li>
              <li className="svg-git">Git</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
