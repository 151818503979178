import React, { useState } from "react";
import ContactForm, { ContactFailure, ContactSuccess } from "./ContactForm";

// Might not be needed, if Axios works without it
const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
};

const Contact = () => {
  const [formState, setFormState] = useState({
    status: "ready",
    errors: [],
  });

  return (
    <div
      className="uk-section uk-background-cover uk-background-top-center uk-flex uk-flex-center"
      id="contact"
    >
      <div className="uk-container uk-container-small uk-text-center">
        <h2>Contact</h2>
        <p>
          i'd love to connect and talk about what we can build together! drop me
          a line, and I will gladly follow up.
        </p>
        {formState.status === 'failure' ? (
          <ContactFailure errors={formState.errors} />
        ) : null }
        {formState.status === "success" ? (
          <ContactSuccess />
        ) : (<ContactForm formState={formState} setFormState={setFormState} />
        )}
      </div>
    </div>
  );
};

export default Contact;
