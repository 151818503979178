import React from "react"

const HomeParallax = () => {
  return (
    <div
      className="uk-section uk-height-large uk-background-cover@s uk-light uk-flex"
      id="gratuitous-parallax-image"
      data-uk-parallax="bgy: -300"
    ></div>
  )
}

export default HomeParallax
