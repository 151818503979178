import React from "react";

import Layout from "../components/Layout";
import About from "../components/About";
import HomeParallax from "../components/HomeParallax";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import ProjectGrapesShopper from "../components/ProjectGrapesshopper";
import ProjectDelveNYC from "../components/ProjectDelveNYC";
import Hero from "../components/Hero";

const Home = () => {
  return (
    <Layout title="Ziv Karmi | Software Engineer in NYC">
      <Hero />
      <About />
      <HomeParallax />
      <Projects />
      <Contact />
      <ProjectGrapesShopper />
      <ProjectDelveNYC />
    </Layout>
  );
};

export default Home;
