import React from "react"

const ProjectGrapesShopper = () => {
  return (
    <div id="oc-grapesshopper" className="uk-modal-full" data-uk-modal>
      <div className="uk-modal-dialog uk-height-viewport uk-height-auto@s">
        <button
          className="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
        ></button>
        <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
          <div
            id="grapesshopper-logo"
            className="uk-background-cover uk-width-1-1 uk-width-1-3@s uk-width-1-1@s uk-height-small uk-height-viewport@s"
          ></div>
          <div className="uk-padding-large uk-width-2-3@s uk-width-1-1">
            <div className="uk-container uk-container-xsmall@s uk-width-xlarge@m uk-margin-remove-left uk-padding-remove-left">
              <h3>buy your wines online</h3>
              <p>
                GrapesShopper is a concept e-commerce shop aiming to connect its
                potential clientele with great wines using an intuitive customer
                experience.
              </p>
              <p>
                I had a blast with this project, as I worked in e-commerce for 9
                years and was excited to see what my team and I could accomplish
                in roughly a week and a half's time.
              </p>
              <p>
                Given my background, I was able to quickly identify a helpful
                database schema, and conceptualize what priority features should
                be worked into our PoC and ultimate deliverable. I decided to
                learn Sass on my own and implement it for this project, as well.
              </p>
              <p>
                <strong>view this project:</strong>
              </p>
              <ul
                className="view-project uk-flex uk-container uk-container-xsmall"
                data-uk-grid
              >
                <li>
                  <a href="https://grapes-shopper.herokuapp.com/">Deployed</a>
                </li>
                <li>
                  <a href="https://github.com/Grace-Shopper-Team-Grapes/grapes-shopper">
                    Repository
                  </a>
                </li>
              </ul>
              <p>
                <strong>technologies used:</strong>
              </p>
              <ul
                className="technologies uk-child-width-1-2 uk-grid-small"
                data-uk-grid
              >
                <li className="svg-html5">HTML5</li>
                <li className="svg-css3">CSS3</li>
                <li className="svg-js">JavaScript / ES6</li>
                <li className="svg-sass">SASS</li>
                <li className="svg-node">Node.js</li>
                <li className="svg-express">Express.js</li>
                <li className="svg-react">React</li>
                <li className="svg-redux">Redux</li>
                <li className="svg-psql">PostgreSQL</li>
                <li className="svg-sqlize">Sequelize.js</li>
                <li className="svg-wbpk">Webpack</li>
                <li className="svg-git">Git</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectGrapesShopper
