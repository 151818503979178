import React from "react"

const ProjectDelveNYC = () => {
  return (
    <div id="oc-delvenyc" className="uk-modal-full" data-uk-modal>
      <div className="uk-modal-dialog uk-height-viewport uk-height-auto@s">
        <button
          className="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
        ></button>
        <div className="uk-grid-collapse uk-flex-middle" data-uk-grid>
          <div
            id="delvenyc-logo"
            className="uk-background-cover uk-width-1-1 uk-width-1-3@s uk-width-1-1@s uk-height-medium uk-height-viewport@s"
          ></div>
          <div className="uk-padding-large uk-width-2-3@s uk-width-1-1">
            <div className="uk-container uk-container-xsmall@s uk-width-xlarge@m uk-margin-remove-left uk-padding-remove-left">
              <h3>Visually Showcasing NYC Datasets</h3>
              <p>
                Delve.NYC is a website that visually relays information about
                areas of New York City using a choropleth map.
              </p>
              <p>
                Our mission with this project was to allow users who may be
                looking to move to different neighborhoods in New York City to
                compare those areas based on different criteria.
              </p>
              <p>
                By leveraging NYC's Open Data project, we were able to gather
                GeoJSON files to help outline a map of NYC using SVG. We
                selected a number of datasets to base our visualizations on,
                including noise pollution and restaurant health grades. We
                paired the values from these datasets to the geographic data we
                had and stored the associated data in MongoDB, using schemas
                written in Mongoose.
              </p>
              <p>
                With D3.js we were able to calculate color scales for our
                choropleth map, as well as build various graphs for more
                detailed comparisons.
              </p>
              <p>
                <strong>view this project:</strong>
              </p>
              <ul
                className="view-project uk-flex uk-container uk-container-xsmall"
                data-uk-grid
              >
                <li>
                  <a href="http://delve.nyc/">Deployed</a>
                </li>
                <li>
                  <a href="https://github.com/super-smash-brothers/delve-nyc">
                    Repository
                  </a>
                </li>
              </ul>
              <p>
                <strong>technologies used:</strong>
              </p>
              <ul
                className="technologies uk-child-width-1-2 uk-grid-small"
                data-uk-grid
              >
                <li className="svg-html5">HTML5</li>
                <li className="svg-css3">CSS3</li>
                <li className="svg-js">JavaScript / ES6</li>
                <li className="svg-sass">SASS</li>
                <li className="svg-react">React</li>
                <li className="svg-d3">D3.js</li>
                <li className="svg-mongo">MongoDB</li>
                <li className="svg-wbpk">Webpack</li>
                <li className="svg-git">Git</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDelveNYC
