import React, { useState } from "react";
import axios from "axios";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = ({ formState, setFormState }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const errors = [];
    for(const field in formData) {
      if(isEmpty(formData[field])) {
        errors.push(`${field} field cannot be empty.`);
        console.log(`added to errors for field ${field}`)
      }
    }

    if(errors.length) {
      console.log('in errors.length')
      setFormState({
        status: "failure",
        errors
      })
      console.log(formState)
      return false;
    }

    trackCustomEvent({
      category: "Contact Button",
      action: "Click",
      label: "Portfolio Contact Form",
    });

    try {
      await axios.post("/", encode({ "form-name": "contact", ...formData }));
      setFormState({
        ...formState,
        status: "success",
      });
    } catch (e) {
      setFormState({
        ...formState,
        status: "failure",
      });
      console.error(e);
    }
  }

  const isEmpty = (str) => (/^\s*$/).test(str);

  return (
    <form
      name="contact"
      method="post"
      action="/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className="uk-grid-small"
      data-uk-grid
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="uk-width-1-2@s">
        <input
          id="contactName"
          name="name"
          className="uk-input"
          type="text"
          placeholder="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="uk-width-1-2@s">
        <input
          id="contactEmail"
          name="email"
          className="uk-input"
          type="email"
          placeholder="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="uk-width-1-1">
        <textarea
          id="contactMessage"
          name="message"
          className="uk-textarea uk-height-small"
          placeholder="message&hellip;"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="uk-width-1-1">
        <button
          id="contactSend"
          type="submit"
          className="uk-button uk-text-lowercase uk-button-large uk-padding-large uk-padding-remove-vertical"
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;

export const ContactSuccess = () => {
  return (
    <div className="uk-width-1-1">
      <p id="contactSuccess" className="uk-text-lowercase uk-padding-small">
        your message has been sent! i'll be in touch soon.
      </p>
    </div>
  );
};

export const ContactFailure = ({ errors }) => {
  return (
    <div className="uk-width-1-1">
      <div id="contactFailure" className="uk-text-lowercase uk-padding-small uk-margin-small-bottom">
        there was an error sending your message. please try again.
        <ul className="uk-margin-remove-bottom">
        {errors.map(e => (
          <li>{e}</li>
        ))}
      </ul>
      </div>
    </div>
  );
};
