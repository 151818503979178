import React from "react";

import grapesshopperLogo from "../images/grapesshopper-logo.png";
import delvenycLogo from "../images/delve-nyc-logo-text.png";

const Projects = () => {
  return (
    <div className="uk-section" id="projects">
      <div className="uk-container uk-container-small uk-text-center">
        <h2 className="uk-margin-large-bottom">Projects</h2>
        <div
          className="uk-child-width-1-2@s uk-grid-large"
          data-uk-height-match="target: > #proj-grapesshopper > .uk-card"
          data-uk-grid
        >
          <div>
            <a
              id="proj-grapesshopper"
              href="#oc-grapesshopper"
              data-uk-toggle="target: #oc-grapesshopper"
            >
              <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <img src={grapesshopperLogo} alt="GrapesShopper" />
              </div>
            </a>
          </div>
          <div>
            <a
              id="proj-delvenyc"
              href="#oc-delvenyc"
              data-uk-toggle="target: #oc-delvenyc"
            >
              <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <img src={delvenycLogo} alt="Delve.NYC" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
